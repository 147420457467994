import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo2 from "../../assets/img/logo/logo1.png";
import home from "../../assets/img/home.svg";
import finolex from "../../assets/img/logo/finolex1.png";
// import logoMobile from "../../assets/img/logo/logo-mobile.png";
import user from "../../assets/img/usericon.png";
import reg from "../../assets/img/reg.svg";
import rejected from "../../assets/img/rejcted.svg";
import pendingFa from "../../assets/img/pendingFa.svg";
import retailer from "../../assets/img/retailer.png";
import faq from "../../assets/img/faq.svg";
import tnc from "../../assets/img/tnc.svg";
import req from "../../assets/img/req.svg";
import abtfinolex from "../../assets/img/abtfinolex.svg";
import contact from "../../assets/img/contact.svg";
import registration from "../../assets/img/registration.png";
import report from "../../assets/img/report.svg";
import redeem from "../../assets/img/redeem.svg";
import { Menubar } from "primereact/menubar";
import { FaLock } from "react-icons/fa";
export default function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const email = "cc@finolexsuperplumber.com";
  const subject = "";
  const body = "";

  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  let userType = localStorage.getItem("user_type");
  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    localStorage.clear();
  };

  //HO sales navbar

  const items = [
    {
      label: "Dashboard",
      url: "/dashboardSales",
    },

    {
      label: "Registration",
      url: "/registration",
    },

    {
      label: "Rejected",
      url: "/rejected_s",
    },

    {
      label: " Pending For approval KYC",
      url: "/pending_for_approval_S",
    },
    {
      label: "Tech Faq's",
      url: "/sales_faq",
    },
    {
      label: "Change Password",
      url: "/change_password",
    },

    {
      label: " Reports",
      icon: "pi pi-fw pi-pencil",
      items: [
        {
          label: "Enrollment Report",
          url: "/enrollment_report_s",
        },
        {
          label: "Invoice Report",
          url: "/invoice_report_s",
        },
        {
          label: "Active Plumbers Report",
          url: "/active_plumbers_report_s",
        },
        {
          label: "Inactive Plumbers Report",
          url: "/inActive_plumbers_report_s",
        },

        {
          label: "Dormant 1 Plumbers Report",
          url: "/dormant1_plumbers_report_s",
        },

        {
          label: "Dormant 2 Plumbers Report",
          url: "/dormant2_plumbers_report_s",
        },
        {
          label: "Redemption Report",
          url: "/redemption_report_s",
        },

        {
          label: "Retailer Details Report",
          url: "/retailer_details_report_s",
        },

        {
          label: "Ticket Raise Report",
          url: "/ticket_raise_report_s",
        },
      ],
    },
  ];

  const start = (
    <img
      alt="logo"
      src="static/media/logo2.d23f3f0ed169b539f668.png"
      height="40"
      className="mr-2"
    ></img>
  );
  //HO retailer navbar

  const itemsOne = [
    {
      label: "Dashboard",
      url: "/dashboard_retailer",
    },
    {
      label: "Retailer",
      icon: "pi pi-fw pi-pencil",
      items: [
        {
          label: "Approved",
          url: "/approved_retailer",
        },
        {
          label: "Pending for Approval",
          url: "/pending_for_approval_retailer",
        },
        {
          label: "Rejected",
          url: "/rejected_retailer",
        },
      ],
    },

    {
      label: " FAQ's",
      url: "/faq_retailer",
    },

    {
      label: "  T & C",
      url: "/tncretailer",
    },

    {
      label: "Request Your Concern",
      url: "/request_your_concernretailer",
    },

    {
      label: "  About Us",
      icon: "pi pi-fw pi-pencil",
      items: [
        {
          label: "About Finolex Pipes",
          url: "/AboutFinolexPipes",
        },
        {
          label: "About Program",
          url: "/AboutProgram",
        },
      ],
    },
    {
      label: "Change Password",
      url: "/change_password",
    },
    {
      label: " Reports",
      icon: "pi pi-fw pi-pencil",
      items: [
        {
          label: "Enrollment Report",
          url: "/enrollment_report",
        },
        {
          label: "Invoice Report",
          url: "/invoice_report",
        },
        {
          label: "Redemption Report",
          url: "/redemption_report",
        },
        {
          label: "Ticket Raise Report",
          url: "/ticket_raise_report",
        },
      ],
    },
  ];

  const startOne = (
    <img
      alt="logo"
      src="static/media/logo2.d23f3f0ed169b539f668.png"
      height="40"
      className="mr-2"
    ></img>
  );

  //HO mobile navbar
  const itemsTwo = [
    {
      label: "Dashboard",
      url: "/dashboardAdmin",
    },
    {
      label: "Invoice Approval",
      url: "/invoiceApproval",
    },
    {
      label: "Redemption",
      url: "/redemptionApproval",
    },
    {
      label: " Reports",
      icon: "pi pi-fw pi-pencil",
      items: [
        {
          label: "Enrollment Report",
          url: "/enrollment_report",
        },
        {
          label: "Invoice Report",
          url: "/invoice_report",
        },
        {
          label: "Invoice Rejection Report",
          url: "/invoice_rejection_report",
        },
        {
          label: "Cumulative Point Report",
          url: "/cumulative_point_report",
        },
        {
          label: "Redemption Report",
          url: "/redemption_report",
        },
        {
          label: "Login Credential Report",
          url: "/login_credential_report",
        },
        {
          label: "Login Report",
          url: "/login_report",
        },
        {
          label: "Kyc Pending Report",
          url: "/kyc_pending_report",
        },
        {
          label: "SMS Report",
          url: "/sms_report",
        },
        {
          label: "Ticket Raise Report",
          url: "/ticket_raise_report",
        },
        {
          label: "Plumber Leader Board Report",
          url: "/plumber_leader_board_report",
        },
        {
          label: "Libility Report",
          url: "/liability_report",
        },
        {
          label: "Campaign Transaction Report",
          url: "/campaign_transaction_report",
        },
        {
          label: "Blocked Member Report",
          url: "/blocked_member_report",
        },
        {
          label: "Anomoly Report",
          url: "/anomoly_report",
        },
        // {
        //   label: "Dealer Master Report",
        //   url: "/dealer_master_report",
        // },
      ],
    },
  ];

  const startTwo = (
    <img
      alt="logo"
      src="static/media/logo2.d23f3f0ed169b539f668.png"
      height="40"
      className="mr-2"
    ></img>
  );

  return (
    <>
      <div className="wrapper home-2">
        <header>
          <div className="header-container header-sticky sticky">
            <div className="header-top-area">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12 text-left">
                    <div className="header-top-menu">
                      <ul className="timing">
                        <li className="">
                          <Link to={mailtoLink}>
                            <i className="fa fa-envelope"></i>
                            cc@finolexsuperplumber.com
                          </Link>
                        </li>
                        <li>
                          <Link>
                            <i className="fa fa-clock-o"></i> Mon - Sat 09.30 AM
                            - 5.30 PM
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12 text-right">
                    <div className="header-top-menu">
                      <ul className="timing">
                        <li className="support hidden-xs">
                          <Link>
                            <i className="fa fa-phone"></i>18002024289
                          </Link>
                        </li>
                        {userType === "SALES" ||
                        userType === "RETAILER" ||
                        userType === "HO" ||
                        userType === "FA" ? (
                          <li className="account" ref={dropdownRef}>
                            <Link
                              to="#"
                              className=""
                              onClick={handleDropdownToggle}
                            >
                              Welcome <i className="fa fa-user usernamei"></i>{" "}
                              <i className="fa fa-angle-down"></i>
                            </Link>
                            {isDropdownOpen && (
                              <div className="userdrop dropdown-menu-right">
                                <div className="user-profile-data1">
                                  <div className="row">
                                    <div className="col-4 profile-img">
                                      <div className="img-profile">
                                        <img
                                          className="avatar"
                                          src={user}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="col-8 profile-content">
                                      <div className="username">
                                        {localStorage.getItem("user_name")}
                                      </div>
                                      <div className="bio">
                                        Finolex Unique Id:
                                        {localStorage.getItem("userUniq_id")}
                                      </div>
                                      <div className="bio">
                                        <Link
                                          to="/"
                                          className="btn login_white_btn"
                                          onClick={handleLogout}
                                        >
                                          <i className="fa fa-power-off"></i>
                                          Logout
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </li>
                        ) : (
                          <li className="account">
                            <Link to="/login" className="login navigator">
                              <i className="fa fa-sign-in"></i> Login{" "}
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-middel-area hidden-xs">
              <div className="container">
                <div className="row">
                  <div className="col-md-2 col-sm-2 col-xs-6">
                    <div className="logo logo1">
                      <Link to="/">
                        <img src={logo2} alt="" />
                      </Link>
                    </div>
                  </div>
                  <div
                    className={
                      userType
                        ? "col-md-10 col-sm-10 col-xs-10 hidden-xs"
                        : "col-md-8 col-sm-8 hidden-xs"
                    }
                  >
                    <div className="main-menu-area">
                      <nav>
                        {!userType ? (
                          <ul className="main-menu">
                            <li>
                              <Link to="/">
                                <img src={home} alt="" /> Home
                              </Link>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}

                        {userType === "SALES" ? (
                          <ul className="main-menu">
                            <li>
                              <Link to="/dashboardSales">
                                <img src={home} alt="" /> Dashboard
                              </Link>
                            </li>
                            <li>
                              <Link to="/registration">
                                <img src={registration} alt="" /> Registration
                              </Link>
                            </li>
                            <li>
                              <Link to="/registered_s">
                                <img src={reg} alt="" /> Registered
                              </Link>
                            </li>
                            <li>
                              <Link to="/rejected_s">
                                <img src={rejected} alt="" /> Rejected
                              </Link>
                            </li>
                            <li>
                              <Link to="/pending_for_approval_S">
                                <img src={pendingFa} alt="" /> Pending For
                                approval KYC
                              </Link>
                            </li>
                            <li>
                              <Link to="/sales_faq">
                                <img src={faq} alt="" /> Tech FAQ's
                              </Link>
                            </li>
                            <li>
                              <Link to="/change_password">
                                <FaLock style={{ color: "#0095da" }} />
                                Change Password
                              </Link>{" "}
                            </li>
                            {/* <li><Link to="/scheme_offer_s"> <img src={schemeNoffer} alt='' /> Schemes and Offers</Link></li> */}
                            <li>
                              <Link to="">
                                <img src={report} alt="" /> Reports
                                <i className="fa fa-angle-down"></i>
                              </Link>
                              <ul className="dropdown">
                                <li>
                                  <Link to="/enrollment_report_s">
                                    Enrollment Report
                                  </Link>
                                </li>
                                {/* <li><Link to="/rejected_plumbers_report_s">Rejected Plumbers </Link></li> */}
                                <li>
                                  <Link to="/invoice_report_s">
                                    Invoice Report
                                  </Link>
                                </li>
                                {/* <li><Link to="/pen_approval_claimId_report_s">Pending for Approval Claim ID </Link></li>
                                                                <li><Link to="/rejected_claimId_report_s">Rejected Claim ID </Link></li> */}
                                <li>
                                  <Link to="/active_plumbers_report_s">
                                    Active Plumbers Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/inActive_plumbers_report_s">
                                    Inactive Plumbers Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/dormant1_plumbers_report_s">
                                    Dormant 1 Plumbers Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/dormant2_plumbers_report_s">
                                    Dormant 2 Plumbers Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/redemption_report_s">
                                    Redemption Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/retailer_details_report_s">
                                    Retailer Details Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ticket_raise_report_s">
                                    Ticket Raise Report
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}

                        {userType === "RETAILER" ? (
                          <ul className="main-menu">
                            <li>
                              <Link to="/dashboard_retailer">
                                <img src={home} alt="" /> Dashboard
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <img src={retailer} alt="" /> Retailer
                                <i className="fa fa-angle-down"></i>
                              </Link>
                              <ul className="dropdown">
                                <li>
                                  <Link to="/approved_retailer">Approved </Link>
                                </li>
                                <li>
                                  <Link to="/pending_for_approval_retailer">
                                    Pending for Approval
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/rejected_retailer">Rejected </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to="/faq">
                                <img src={faq} alt="" /> FAQ's
                              </Link>
                            </li>
                            <li>
                              <Link to="/tnc">
                                <img src={tnc} alt="" /> T & C
                              </Link>
                            </li>
                            <li>
                              <Link to="/request_your_concernretailer">
                                <img src={req} alt="" /> Request Your Concern
                              </Link>
                            </li>
                            <li>
                              <Link to="/AboutFinolexPipes">
                                <img src={abtfinolex} alt="" /> About Us
                                <i className="fa fa-angle-down"></i>
                              </Link>
                              <ul className="dropdown">
                                <li>
                                  <Link to="/AboutFinolexPipes">
                                    About Finolex Pipes
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/AboutProgram">About Program </Link>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <Link to="/contact">
                                <img src={contact} alt="" /> Contact Us
                              </Link>
                            </li>
                            <li>
                              <Link to="/change_password">
                                <FaLock style={{ color: "#0095da" }} />
                                Change Password
                              </Link>{" "}
                            </li>
                            <li>
                              <Link to="">
                                <img src={report} alt="" /> Reports
                                <i className="fa fa-angle-down"></i>
                              </Link>
                              <ul className="dropdown">
                                <li>
                                  <Link to="/enrollment_report">
                                    Enrollment Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/invoice_report">
                                    Invoice Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/redemption_report">
                                    Redemption Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/ticket_raise_report">
                                    Ticket Raise Report
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}
                        {userType === "HO" ? (
                          <ul className="main-menu">
                            <li>
                              <Link to="/dashboardAdmin">
                                <img src={home} alt="" /> Dashboard
                              </Link>
                            </li>
                            <li>
                              <Link to="/invoiceApproval">
                                <img src={report} alt="" /> Invoice Approval
                              </Link>
                            </li>
                            {/* <li>
                              <Link to='/redemptionApproval'>
                                <img src = {redeem} alt="" /> Redemption Approval
                              </Link>
                            </li> */}
                            <li>
                              <Link to="">
                                <img src={report} alt="" /> Reports
                                <i className="fa fa-angle-down"></i>
                              </Link>
                              <ul className="dropdown">
                                <li>
                                  <Link to="/enrollment_report">
                                    Enrollment Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/invoice_report">
                                    Invoice Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/invoice_rejection_report">
                                    Invoice Rejection Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/cumulative_point_report">
                                    Cumulative Point Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/redemption_report">
                                    Redemption Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/login_credential_report">
                                    Login Credential Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/login_report">Login Report</Link>
                                </li>
                                <li>
                                  <Link to="/kyc_pending_report">
                                    Kyc Pending Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/sms_report">SMS Report</Link>
                                </li>

                                <li>
                                  <Link to="/ticket_raise_report">
                                    Ticket Raise Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/plumber_leader_board_report">
                                    Plumber Leader Board Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/liability_report">
                                    Liability Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/campaign_transaction_report">
                                    Campaign Transaction Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/blocked_member_report">
                                    Blocked Member Report
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/anomoly_report">
                                    Anomoly Report
                                  </Link>
                                </li>
                                {/* <li>
                                  <Link to="/dealer_master_report">
                                    Dealer Master
                                  </Link>
                                </li> */}
                              </ul>
                            </li>
                            <li>
                              <Link to="/AboutFinolexPipes">
                                <img src={abtfinolex} alt="" /> About Us
                                <i className="fa fa-angle-down"></i>
                              </Link>
                              <ul className="dropdown">
                                <li>
                                  <Link to="/AboutFinolexPipes">
                                    About Finolex Pipes
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/AboutProgram">About Program </Link>
                                </li>
                                <li>
                                  <Link
                                    target="_blank"
                                    to="https://loyaltyfinolex.blob.core.windows.net/finolex/Catalogue.pdf"
                                  >
                                    Finolex Product List{" "}
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/faq">FAQ's </Link>
                                </li>
                                <li>
                                  <Link to="/tnc">T & C </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}
                        {userType === "FA" ? (
                          <ul className="main-menu">
                            <li>
                              <Link to="/dashboardFinance">
                                <img src={redeem} alt="" /> Redemption Approval
                              </Link>
                            </li>
                            <li>
                              <Link to="/finance/invoiceApproval">
                                <img src={report} alt="" /> Invoice Approval
                              </Link>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}
                      </nav>
                    </div>
                  </div>
                  {!userType ? (
                    <div className="col-md-2 col-sm-2 col-xs-6 text-right">
                      <div className="finlogo">
                        <Link to="#">
                          <img src={finolex} alt="" />
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="mobile-menu-area hidden-sm hidden-md hidden-lg">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="mobile-menu">
                      <nav style={{ display: "block" }}>
                        <ul className="main-menu">
                          {!userType ? (
                            <ul className="main-menu">
                              <li>
                                <Link to="/">
                                  <img src={home} alt="" /> Home
                                </Link>
                              </li>
                            </ul>
                          ) : (
                            ""
                          )}

                          {userType === "SALES" ? (
                            <ul className="main-menu">
                              <div className="card">
                                <Menubar model={items} start={start} end={""} />
                              </div>
                            </ul>
                          ) : (
                            ""
                          )}

                          {userType === "RETAILER" ? (
                            <ul className="main-menu">
                              <div className="card">
                                <Menubar
                                  model={itemsOne}
                                  start={startOne}
                                  end={""}
                                />
                              </div>
                            </ul>
                          ) : (
                            ""
                          )}
                          {userType === "HO" ? (
                            <ul className="main-menu">
                              <div className="card">
                                <Menubar
                                  model={itemsTwo}
                                  start={startTwo}
                                  end={""}
                                />
                              </div>
                            </ul>
                          ) : (
                            ""
                          )}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}
